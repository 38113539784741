import React, { Component } from 'react';
import { navigate } from 'gatsby-link';

import AccountFrame from '../../components/account/AccountFrame';
import { getCmsValue } from '../../utils/utils';
import withAccount from '../../containers/plugins/withAccount';

class Reset extends Component {
  constructor(props) {
    super(props);

    this.initialState = {
      resetUrl: '',
      password: '',
      passwordConfirm: '',
      loading: false,
      error: null,
    };

    this.state = {
      ...this.initialState,
    };

    this.reset = this.reset.bind(this);
    this.onChange = this.onChange.bind(this);
  }

  componentDidMount() {
    const { location } = this.props;

    this.setState((prevState) => ({
      ...prevState,
      resetUrl: new URLSearchParams(location.search).get('url'),
    }));
  }

  async reset(e) {
    e.preventDefault();

    const { resetPassword } = this.props;
    const { resetUrl, password, passwordConfirm } = this.state;

    if (password !== passwordConfirm) {
      this.setState((prevState) => ({
        ...prevState,
        error: 'Passwords do not match',
      }));
      return;
    }

    this.setState((prevState) => ({
      ...prevState,
      loading: true,
    }));

    const response = await resetPassword({ resetUrl, password });

    this.setState((prevState) => ({
      ...prevState,
      loading: false,
    }));

    if (response.customerUserErrors.length === 0) {
      navigate(`/${this.props.site.siteUID}/account`);
    } else {
      this.setState((prevState) => ({
        ...prevState,
        error: response.customerUserErrors[0].message,
      }));
    }
  }

  onChange(e) {
    this.setState((prevState) => ({
      ...prevState,
      [e.target.name]: e.target.value,
    }));
  }

  render() {
    const { site } = this.props;
    const { password, passwordConfirm } = this.state;

    return (
      <AccountFrame
        title={getCmsValue(site.strings.accountPage__reset__title, site)}
        {...this.props}
      >
        <div className="activate">
          <div className="account-section account-section__information">
            <div className="account-section__title">
              <h3>
                {getCmsValue(site.strings.accountPage__reset__title, site)}
              </h3>
            </div>
            <form className="account-section__form" onSubmit={this.reset}>
              <div className="form__input-wrapper">
                <label htmlFor="password">
                  {getCmsValue(site.strings.accountPage__reset__password, site)}
                  <input
                    name="password"
                    type="password"
                    value={password}
                    onChange={this.onChange}
                    required
                  />
                </label>

                <label htmlFor="passwordConfirm">
                  {getCmsValue(
                    site.strings.accountPage__reset__passwordConfirm,
                    site
                  )}
                  <input
                    name="passwordConfirm"
                    type="password"
                    value={passwordConfirm}
                    onChange={this.onChange}
                    required
                  />
                </label>
                <span className="error">{this.state.error}</span>
              </div>

              <div className="form__submit-wrapper">
                <button type="submit" disabled={this.state.loading}>
                  {getCmsValue(site.strings.accountPage__reset__reset, site)}
                </button>
              </div>
            </form>
          </div>
        </div>
      </AccountFrame>
    );
  }
}

export default withAccount(Reset);
